import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import { initVue, vuetify } from "@flyt-frontend/shared-library";

// Auth0 configuration
const auth0Config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTHO_AUDIENCE,
  backendUri: process.env.VUE_APP_BACKEND_URI
};

initVue(Vue, router, auth0Config); // Initialises vue with a common config

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
