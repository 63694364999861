import Vue from "vue";
import Vuex from "vuex";

import {
  createStoreModule as createSharedModule,
  SHARED_MODULE
} from "@flyt-frontend/shared-state";
import {
  createStoreModule as createMenusModule,
  MENUS_MODULE
} from "@flyt-frontend/capability-menus";
import {
  createStoreModule as createOrdersModule,
  ORDERS_MODULE
} from "@flyt-frontend/capability-orders";
import {
  createStoreModule as createRestaurantsModule,
  RESTAURANTS_MODULE
} from "@flyt-frontend/capability-restaurants";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    [SHARED_MODULE]: createSharedModule(),
    [MENUS_MODULE]: createMenusModule(),
    [ORDERS_MODULE]: createOrdersModule(),
    [RESTAURANTS_MODULE]: createRestaurantsModule()
  }
});
