import Vue from "vue";
import VueRouter from "vue-router";
import { AuthGuard } from "@flyt-frontend/shared-library";

import { initVue } from "@flyt-frontend/shared-library";

// Auth0 configuration
const auth0Config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTHO_AUDIENCE,
  backendUri: process.env.VUE_APP_BACKEND_URI
};

const router = new VueRouter({
  mode: "history"
});

Vue.use(VueRouter);
initVue(Vue, router, auth0Config); // Initialises vue with a common config

import { createRoutes as generateMenusRoutes } from "@flyt-frontend/capability-menus";
import { createRoutes as generateOrdersRoutes } from "@flyt-frontend/capability-orders";
import { createRoutes as generateRestaurantsRoutes } from "@flyt-frontend/capability-restaurants";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@flyt-frontend/shared-library/components/shared/Login"
      )
  },
  {
    path: "/capabilities",
    name: "AppHome",
    component: () =>
      import(/* webpackChunkName: "capabilities" */ "./views/Capabilities"),
    beforeEnter: AuthGuard
  },
  ...generateMenusRoutes("", AuthGuard),
  ...generateOrdersRoutes("", AuthGuard),
  ...generateRestaurantsRoutes("", AuthGuard)
];

router.addRoutes(routes);

export default router;
