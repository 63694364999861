<template>
  <v-app>
    <v-app-bar app v-if="!isHome">
      <Header />
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container class="fluid" :class="{ 'fill-height': isHome }">
        <v-row align="center" justify="center">
          <v-col lg="8" md="12" sm="12">
            <Breadcrumbs v-if="!isHome" class="pa-0" />
            <router-view />
          </v-col>
        </v-row>
      </v-container>
      <ErrorNotifier />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@flyt-frontend/shared-library/components/shared/Header";
import Breadcrumbs from "@flyt-frontend/shared-library/components/shared/Breadcrumbs";
import ErrorNotifier from "@flyt-frontend/shared-library/components/shared/ErrorNotifier";

const titleSite = "Flyt Connect";

export default {
  name: "App",
  components: {
    Header,
    Breadcrumbs,
    ErrorNotifier
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  },
  watch: {
    $route: to => {
      // Example: "Capabilities - Flyt Connect" or just "Flyt Connect"
      document.title = to.meta.title
        ? to.meta.title + " | " + titleSite
        : titleSite;
    }
  }
};
</script>

<style lang="scss">
@import "~@flyt-frontend/shared-library/styles/main.scss";
</style>
